import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import api from "@/services/api";
import url from "@/router/urls";
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { appActions } from "@/store/actions/app.actions";
import { useDispatch } from 'react-redux';
import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";
import { alertActions } from "@/store/actions/alert.actions";
import { reverse } from 'named-urls';

import Button from "@Elements/Button/Button";
import LinkButton from "@Elements/Button/LinkButton";
import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";
import SearchTranscriptions from "@Elements/Search/SearchTranscriptions";
import Timeline from '@Components/Events/Timeline/Timeline';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Details from '@Pages/Event/Details';
import Teams from '@Pages/Event/Teams';
import Pages from '@Pages/Event/Pages';
import Alert from "@Elements/Alert/Alert";
import ModalDeleteEvent from '@Pages/Event/parts/Event/ModalDeleteEvent';
import ModalPseudonymWarning from '@Pages/Event/parts/Event/ModalPseudonymWarning';

import styles from "@Pages/Event/Event.module.sass";

const Event = () => {
	const [ searchString, setSearchString ] = useState("");
	const [ event, setEvent] = useState({});
	const [transcriptions, setTranscriptions] = useState([]);
	const [key, setKey] = useState('details');
	const [teams, setTeams] = useState([]);
	const [attendees, setAttendees] = useState([]);
	const [modalDelete, setModalDelete] = useState(false);
	const [userParticipate, setUserParticipate] = useState(false);
	const [userConfirmed, setUserConfirmed] = useState(false);
	const [userTeamId, setUserTeamId] = useState(null);
	const [isAdmin, setIsAdmin] = useState(false);
	const [started, setStarted] = useState(false);
	const [finished, setFinished] = useState(false);
	const [showPseudonymWarningModal, setShowPseudonymWarningModal] = useState(false);

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();

	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);
	const isLoggingEnd = useSelector(state => state.auth.credentials.isLoggingEnd);
	const userName = useSelector(state => state.auth.credentials.name);
	const userEmail = useSelector(state => state.auth.credentials.email);
	const securityGroup = useSelector((state) => state.auth.credentials.securityGroup);
	const joinedEventOnce = false;

	const { id, tab } = useParams();

	const breadcrumbsList = [
		{ label: "home", id: 1 },
		{ label: t('events.allEvents'), id: 2, link: url.events },
		{ label: event.name ? event.name : t('events.event'), id: 3  },
	];

	const checkUserAttend = (eventToCheck) => {
		if(isLoggedIn) {
			const participate = eventToCheck.attendees.some(attendee => attendee.user.email === userEmail);
			const confirmed = eventToCheck.attendees.find(attendee => attendee.user.email === userEmail);

			if(confirmed) {
				setUserConfirmed(confirmed.confirmed);

				const currentUserTeam = teams.find(team => team.name === confirmed.team?.name);
				setUserTeamId(currentUserTeam?.id);
			}

			setUserParticipate(participate);
		}
	};

	const getEvent = async () => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`/events/${id}`);

			if(!res) {
				dispatch(appActions.setNotFound(true));
				dispatch(appActions.setLoading(false));
			} else if(res.status === 200) {
				setEvent(res.data);
				dispatch(appActions.setLoading(false));
				
			}

		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.warnings.getEvent'),
				close: true,
			}));
		}
	};
	
	const getTranscriptionsEvent = async () => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`/events/${id}/transcriptions?page=1&perpage=1000`);

			if (res.status === 200) {
				setTranscriptions(res.data);
				dispatch(appActions.setLoading(false));
			}

		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.warnings.getTranscription'),
				close: true,
			}));
		}
	};

	const signIn = async () => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.put(`/events/${id}/enroll`, { headers: { 'Content-Type': 'application/json'}});
			if (res.status === 200) {
				setUserConfirmed(true);
				getTeams();
				getEvent();
				dispatch(appActions.setLoading(false));
			}
		} catch (error) {
			if (error.data === "Event is not open for enrollment") {
				dispatch(alertActions.setAlert({
					type: "danger",
					icon:  "icon-circle-warning-empty",
					text: t('events.warnings.signIn'),
					close: true,
				}));
			} else {
				dispatch(alertActions.setAlert({
					type: "danger",
					icon:  "icon-circle-warning-empty",
					text: t('events.warnings.participate'),
					close: true,
				}));
			}
			dispatch(appActions.setLoading(false));

		}
	};

	const changeParticipation = async (participate) => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.put(`/events/${id}/attendee-confirm?attend=${participate}`);
			if (res?.status === 200) {
				dispatch(alertActions.setAlert({
					type: "success",
					icon:  "icon-accept",
					text: participate ? t('events.messages.participationConfirmed') : t('events.messages.participationResigned'),
					close: true,
				}));
				dispatch(appActions.setLoading(false));
				checkUserAttend(res.data);
				setEvent(res.data);
				if (participate || res.data.announced) {
					getTeams();
				} else {
					history.push(url.events);
				}
				return true;
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			if (error?.status !== 401) {
				dispatch(alertActions.setAlert({
					type: "danger",
					icon:  "icon-circle-warning-empty",
					text: participate ? t('events.warnings.participate') : t('events.warnings.noParticipate'),
					close: true,
				}));
			}
		}
		return false;
	};
	
	const getTeams = async () => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`events/${id}/teams`);
			if (res.status === 200) {
				setTeams(res.data);
				dispatch(appActions.setLoading(false));
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.warnings.getTeams'),
				close: true,
			}));
		}
	};

	const deleteEvent = async () => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.delete(`/events/${id}`, {data: [id]});
			if (res.status === 204) {
				history.push(url.events);
				dispatch(appActions.setLoading(false));
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.warnings.deleteEvent'),
				close: true,
			}));
		}
	};

	const sortAttendes = (a, b) => {
		if (a.team === null && b.team !== null) {
			return -1; // "null" comes first
		} else if (a.team !== null && b.team === null) {
			return 1; // "null" comes second
		} else if (a.team && b.team) {
			if (a.team < b.team) {
				return -1;
			}
			if (a.team > b.team) {
				return 1;
			}
		} else {
			return 0;
		}
		
	};

	const getAttendees = (data) => {
		const transformedArray = data.reduce((result, item) => {
			const teamName = item.team !== null ? item.team.name : null;

			const user = {
				name: item.user.name,
				userId: item.user.id,
				email: item.user.email,
				characterCount: item.characterCount,
				wordCount: item.wordCount,
				verseCount: item.verseCount,
				status: item.confirmed,
			};

			const team = result.get(teamName);
			if (team) {
				team.users.push(user);
			} else {
				result.set(teamName, { team: teamName, users: [user] });
			}

			return result;
		}, new Map());

		const mapToArray = Array.from(transformedArray.values());

		const sortedArray = mapToArray.sort(sortAttendes);

		setAttendees(sortedArray);
	};

	const closeEnrolment = async () => {
		dispatch(appActions.setLoading(true));
		const dataToSend = {
			"name": event.name,
			"description": event.description,
			"openingInfo": event.opening,
			"organizerEmails":event.organizers.map((organizer) => organizer.email),
			"announced": event.announced,
			"openForEnrollment":  false,
			"startDate": event.startDate,
			"endDate":  event.endDate,
		};	

		try {
			const res = await api.put(`/events/${id}`, dataToSend);
			if(res.status === 200) {
				setEvent(res.data);
			}
		} catch (error) {
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.warnings.closeEnroll'),
				close: true,
			}));
		} finally {
			dispatch(appActions.setLoading(false));
		}
	};

	const startGame = async () => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`events/${id}/pages?fields=id,page-no`, { passAllErrors: true });
			if (res.status === 200) {
				dispatch(appActions.setLoading(false));
				if (res.data.length === 0) {
					throw new Error('No pages found');
				}
				history.push(`/event/${id}/page/${res.data[0].id}`);
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon: "icon-circle-warning-empty",
				text: t('events.warnings.startGame'),
				close: true,
			}));
		}
	};

	useEffect(() => {
		if (!isLoggingEnd) {
			return;
		}
		const queryParams = new URLSearchParams(location.search);

		const decision = queryParams.get('decision');
		if (decision === 'confirm' || decision === 'resign') {
			const participate = decision === 'confirm';
			const promise = changeParticipation(participate);
			promise.then((ok) => {
				if (ok) {
					queryParams.delete('decision');
					history.replace({
						search: queryParams.toString(),
					});
				}
			});
		}
	}, [isLoggingEnd]);

	useEffect(() => {
		if (isLoggingEnd) {
			dispatch(appActions.setLoading(true));
			getEvent();
			getTranscriptionsEvent();
			getTeams();
		}
	}, [id, isLoggingEnd]);

	useEffect(() => {
		if(event?.attendees) {
			checkUserAttend(event);
			getAttendees(event?.attendees);
		}
	}, [event, teams, userEmail]);

	useEffect(() => {
		if(tab) {
			setKey(tab);
		}
	}, [tab]);	

	useEffect(() => {
		dispatch(appActions.setLoading(true));
		const admin = "SUPER_ADMIN" === securityGroup;
		if (id && !admin && event && event.organizers) {
			setIsAdmin(event.organizers.some(organizer => userEmail === organizer.email));
			dispatch(appActions.setLoading(false));
		} else {
			setIsAdmin(admin);
			dispatch(appActions.setLoading(false));
		}
	}, [securityGroup, event, id, isLoggedIn]);

	useEffect(() => {
		if(event) {
			const startDate = new Date(event.startDate);
			const endDate = new Date(event.endDate);
			const now = new Date();
			setStarted(startDate < now && endDate > now);
			setFinished(endDate < now);
		}
	}, [event]);

	return (
		<>	
			<ModalDeleteEvent modalDelete={modalDelete} setModalDelete={setModalDelete} deleteEvent={deleteEvent}/>
			<ModalPseudonymWarning modalPseudonymWarning={showPseudonymWarningModal} setModalPseudonymWarning={setShowPseudonymWarningModal} currentPseudonym={userName} signIn={signIn} />
			<SearchTranscriptions
				setSearchString={ setSearchString }
				searchString={ searchString }
			/>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
			</Container>
			<Container>
				<Alert />
				{userParticipate && !userConfirmed && 
				<div className={ styles["event__info-confirm"] }>
					<div className={ styles["event__info-confirm__text"] }><i className='icon-info' />{t('events.addedToEvent')}</div>
					<div className={ styles["event__info-confirm__buttons"] }>
						<Button variant='secondary' onClick={() => changeParticipation(false)}>{t('events.buttons.signOut')}</Button>
						<Button variant='primary' onClick={() => changeParticipation(true)}>{t('events.buttons.signIn')}</Button>
					</div>
				</div>}
				<div className={ styles["event__header"] } >
					<div className={ styles["event__header__left"] }>
						<h2 className={ styles["event__title"] }>{event.name}</h2>
						<div>
							<Timeline startDate={event.startDate} endDate={event.endDate}/>
						</div>
					</div>
					{isAdmin &&  
					<div className={ styles["event__header__admin-buttons"] }>
						<Button onClick={() => setModalDelete(true)} variant='secondary' className={ styles["event__button--attend"] } >
							{t('events.buttons.delete')}
						</Button>
						<LinkButton href={reverse(`${url.eventCreate}`, { id: id})} variant='secondary' className={ styles["event__button--attend"] }
							disabled={ event.announced && finished }
						>
							{t('events.buttons.edit')}
						</LinkButton>
						{!event.openForEnrollment && 
						<LinkButton href={reverse(`${url.eventOpen}`, { id: id})} variant='primary' className={ styles["event__button--attend"] } >
							{t('events.buttons.open')}
						</LinkButton>}
						{event.openForEnrollment && 
						<Button onClick={() => closeEnrolment()} variant='primary' className={ styles["event__button--attend"] } >
							{t('events.buttons.close')}
						</Button>}
					</div>}
					{!isAdmin && 
					<div className={ styles["event__header__button"] }>
						{!event.openForEnrollment && !userParticipate &&
						<div>
							{t('events.buttons.enrollmentClosed')}
						</div>}
						{!isLoggedIn && !started && !finished && event.openForEnrollment &&
						<Button variant='primary' className={ styles["event__button--attend"] } href={url.auth.login}>
							{t('events.buttons.subscribe')}
						</Button>}
						
						{isLoggedIn && started && event.announced && userParticipate &&
							<Button variant='primary' className={ styles["event__button--attend"] } onClick={() => startGame()}>
								{t('events.buttons.startGame')}
							</Button>}
						{isLoggedIn && !started && !finished && event.openForEnrollment && (!userParticipate ? 
							<Button variant='primary' className={ styles["event__button--attend"] } onClick={() => joinedEventOnce ? signIn() : setShowPseudonymWarningModal(true)}>
								{t('events.buttons.subscribe')}
							</Button> :
							<Button variant='secondary' className={ styles["event__button--attend"] } onClick={() => changeParticipation(false)}>
								{t('events.buttons.unsubscribe')}
							</Button>)}
					</div>}

				</div>
			</Container>
			<Container>
				<Tabs defaultActiveKey='details' 
					id='event-details'
					activeKey={key}
					onSelect={(k) => setKey(k)}
				>
					<Tab eventKey='details' title={t('events.tab.details')} tabClassName={`${styles["event__tab"]} ${key ==='details' && styles["event__tab--active"]}`}>
						<Details thumbnailUrl={event.thumbnailUrl}
							description={event.description}
							openingInfo={event.openingInfo}
							organizers={event.organizers}
							transcriptions={transcriptions.transcriptions}
							eventId={id} isAdmin={isAdmin}
						/>
					</Tab>
					<Tab eventKey='teams' title={t('events.tab.attendesAndTeams')} tabClassName={`${styles["event__tab"]} ${key ==='teams' && styles["event__tab--active"]}`} >
						<Teams teams={teams} attendees={attendees} eventId={id} setTeams={setTeams}
							getEvent={getEvent} getTeams={getTeams} isAdmin={isAdmin}
							isStarted={started && event.announced}
							isFinished={finished && event.announced}
						/>
					</Tab>
					{isLoggedIn && started && userParticipate && event.announced &&
						<Tab eventKey='pages' title={t('events.tab.pagesForTranscription')} tabClassName={`${styles["event__tab"]} ${key ==='pages' && styles["event__tab--active"]}`} >
							<Pages eventId={id} teamId={userTeamId} transcriptions={transcriptions.transcriptions} />
						</Tab>}
				</Tabs>
			</Container>
		</>
	);
};

Event.propTypes = {};

export default Event;