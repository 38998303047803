import React, { useEffect, useState }  from 'react';
import styles from "@Pages/Event/Event.module.sass";
import Form from 'react-bootstrap/Form';
import PropTypes from "prop-types";
import api from "@/services/api";
import url from "@/router/urls";

import { useTranslation } from 'react-i18next';
import { appActions } from "@/store/actions/app.actions";
import { useDispatch } from 'react-redux';
import { useWindowSize } from "@/utils/windowSize";
import { alertActions } from "@/store/actions/alert.actions";
import { reverse } from 'named-urls';

import Button from "@Elements/Button/Button";
import LinkButton from "@Elements/Button/LinkButton";
import Divider from "@Components/Divider/Divider";
import ModalSendMessage from '@Pages/Event/parts/Teams/ModalSendMessage';
import ModalDeleteTeam from '@Pages/Event/parts/Teams/ModalDeleteTeam';
import ModalDeleteUser from '@Pages/Event/parts/Teams/ModalDeleteUser';
import ModalAddUsersTeam from '@Pages/Event/parts/Teams/ModalAddUsersTeam';
import ModalRandomUsers from '@Pages/Event/parts/Teams/ModalRandomUsers';
import ModalResetTeamA from '@Pages/Event/parts/Teams/ModalResetTeamA';
import AddUser from '@Pages/Event/parts/Teams/AddUser';

const Teams = ({teams, attendees, eventId, setTeams, getTeams, getEvent, isAdmin, isStarted, isFinished}) => {
	const [users, setUsers] = useState(0);
	const [assignedTeams, setAssignedsTeam ] = useState([]);
	const [activeAttendees, setActiveAttendees] = useState([]);
	const [mobileTeam, setMobileTeam] = useState([]);
	const [modalVisibleAddUsersTeam, setModalVisibleAddUsersTeam] = useState(false);
	const [modalDeleteTeam, setModalDeleteTeam] = useState(false);
	const [modalDeleteUser, setModalDeleteUser] = useState(false);
	const [modalSendMessage, setModalSendMessage] = useState(false);
	const [modalRandom, setModalRandom] = useState(false);
	const [modalResetA, setModalResetA] = useState(false);
	const [dataToSend, setDataToSend] = useState([]);
	const [teamForModal, setTeamForModal] = useState('');
	const [userToDelete, setUserToDelete] = useState({});

	const firstTeamName = 'Drużyna A';
	const unassignedTeamName = 'null';

	const { t } = useTranslation();
	const isMobile = useWindowSize().width < 769;
	const dispatch = useDispatch();

	const getUsers = (teamsArr) => {
		const sortedArray = teamsArr.filter(team => team.id).sort(sortByName);

		setAssignedsTeam(sortedArray);
	};

	const getNumberCount = (teamToFind, value) => {
		const team = assignedTeams.find(teamsName =>  teamsName.name === teamToFind);
		if(team) {
			return team[value];
		}
	};

	const sortByName = (a, b) => {
		let nameA = '';
		let nameB = '';
		if (Object.prototype.hasOwnProperty.call(a, 'name') && Object.prototype.hasOwnProperty.call(b, 'name'))  {
			nameA = a.name.toUpperCase();
			nameB = b.name.toUpperCase();
		} else {
			nameA = a.toUpperCase();
			nameB = b.toUpperCase();
		}

		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0;
	};

	const findUsers = (e) => {
		const mobileUsers = attendees.find(attendee => attendee.team === mobileTeam?.team);
		const filteredMobileUsers = {
			...mobileUsers,
			users: mobileUsers?.users ? mobileUsers.users.filter(user => user.name.includes(e.target.value)) : [],
		};

		setMobileTeam(filteredMobileUsers);

		const desktopUsers = attendees.map(item => ({
			...item,
			users: item.users.filter(user => user.name.includes(e.target.value) ),
		})).filter(item => item.users.length > 0);
		setActiveAttendees(desktopUsers);
	};

	const getTranscipriotnPagesLength = (pages) => {
		return Object.values(pages).reduce((acc, array) => acc + array.length, 0);
	};

	const onChangeSelect = (e) => {
		let teamValue = '';
		if(e === unassignedTeamName || e.target.value === unassignedTeamName) {
			teamValue = null;
		} else {
			teamValue = e.target.value;
		}
		const team = attendees.find(item => item.team === teamValue);
		setMobileTeam(team);
	};

	const deleteUser = async (email) => {
		try {
			const res = await api.delete(`/events/${eventId}/attendees`, {data: [email]});
			if (res.status === 200) {
				
				dispatch(appActions.setLoading(false));
				getTeams();
				getEvent();
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.teams.warnings.deleteUser'),
				close: true,
			}));
		}
	};

	const addTeam = async () => {
		dispatch(appActions.setLoading(true));
		const teamsName = dataToSend.filter(team => team.name).map(team => team.name).sort(sortByName);

		let missingTeamName = [];

		for (var i = 0; i < teamsName.length; i++) {
			const currentTeam = teamsName[i];
			const nextTeam = teamsName[i + 1];
			const currentLetter = currentTeam.charAt(currentTeam.length - 1);
			const nextLetter = String.fromCharCode(currentLetter.charCodeAt(0) + 1);

			if (missingTeamName.length === 0 && !nextTeam) {
				const team = currentTeam.slice(0, -1) + nextLetter;
				missingTeamName.push(team);
			}
			else if (missingTeamName.length === 0 && nextLetter < nextTeam.charAt(nextTeam.length - 1)) {
				const missingTeam = currentTeam.slice(0, -1) + nextLetter;
				missingTeamName.push(missingTeam);
			}
		}

		if(missingTeamName.length === 0) {
			missingTeamName = [firstTeamName];
		}

		let data = dataToSend.filter(team => team.name);

		data.push({
			name: missingTeamName[0],
			members: [],
			transcriptionPages: {},
			characterCount: 0,
			wordCount: 0,
			verseCount: 0,
		});

		try {
			const res = await api.put(`/events/${eventId}/teams`, data);
			if (res.status === 200) {
				dispatch(appActions.setLoading(false));
				setTeams(res.data);
				getEvent();
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.teams.warnings.addTeam'),
				close: true,
			}));
		}
	};

	useEffect(() => {
		if(teams) {
			getUsers(teams);
			setDataToSend(teams);
		}
	}, [teams]);

	useEffect(() => {
		setActiveAttendees(attendees);
		setUsers(attendees.reduce((acc, item) => acc + item.users.length, 0));
	}, [attendees, teams]);

	useEffect(() => {
		if(attendees && teams) {
			onChangeSelect(unassignedTeamName);
		}
	}, [attendees, teams]);

	return (
		<>
			<ModalAddUsersTeam 
				setModalVisible={setModalVisibleAddUsersTeam} modalVisible={modalVisibleAddUsersTeam} eventId={eventId} attendees={attendees}
				setTeams={setTeams} getEvent={getEvent} teamForModal={teamForModal} dataToSend={dataToSend}
			/>
			<ModalDeleteTeam 
				setModalVisible={setModalDeleteTeam} modalVisible={modalDeleteTeam} eventId={eventId} teamForModal={teamForModal}
				dataToSend={dataToSend} setTeams={setTeams} getEvent={getEvent}
			/>
			<ModalDeleteUser
				setModalVisible={setModalDeleteUser} modalVisible={modalDeleteUser}
				userName={userToDelete.name} doDeleteUser={() => deleteUser(userToDelete.email)}
			/>
			<ModalResetTeamA 
				setModalVisible={setModalResetA} modalVisible={modalResetA} eventId={eventId} teamForModal={teamForModal}
				dataToSend={dataToSend} setTeams={setTeams} getEvent={getEvent}
			/>
			<ModalSendMessage 
				setModalVisible={setModalSendMessage} modalVisible={modalSendMessage} eventId={eventId} 
			/>
			<ModalRandomUsers 
				setModalVisible={setModalRandom} modalVisible={modalRandom} teams={teams} eventId={eventId}
				setTeams={setTeams} getEvent={getEvent}
			/>
			<div className={ styles["teams__top"] }>
				<span className={styles['teams__title']}>{t('events.teams.label')} ({assignedTeams && assignedTeams.length})</span>
			</div>
			{isAdmin && !isFinished &&
			<div className={ styles["teams__top__buttons"] }>
				<Button variant='secondary' onClick={() => setModalRandom(true)}>{t('events.teams.buttons.randomUsers')}</Button>
				<Button variant='primary' onClick={() => addTeam()}>{t('events.teams.buttons.addTeam')}</Button>
			</div>}
			
			{
				(assignedTeams &&  assignedTeams.length > 0) && assignedTeams.map((team, index) => 
					(
						<div key={team.id} className={ styles["teams__item"] }>
							<span className={ styles["teams__item__name"] }>{index + 1}. {team.name}</span> 
							<div className={ styles["teams__item__description"] }>
								{team.transcriptionPages && 
								<div className={ styles["teams__item__inner"] }>
									<div>
										<span className={ styles["teams__item__text"] }>{t('events.teams.pagesToTranscyrption')}</span> 
										<span className={ styles["teams__item__number"] }>
											{getTranscipriotnPagesLength(team.transcriptionPages) === 0 ? !isMobile && 
											<i className='icon-circle-warning-empty' title={t('events.teams.warnings.addTranscriptionsTooltip')} /> : getTranscipriotnPagesLength(team.transcriptionPages)}
										</span>
									</div>
									{(getTranscipriotnPagesLength(team.transcriptionPages) === 0 && isMobile) && 
									<div className={ styles["teams__item__alert"] }> 
										<i className='icon-circle-warning-empty' />
										<span>{t('events.teams.warnings.notAssigned')}</span> 
									</div> }
									{isAdmin && !isFinished &&
									<LinkButton size='small' variant='secondary' href={reverse(`${url.eventPages}`, { id: eventId, teamId: team.id })}>
										{t('events.teams.buttons.assignePages')}
									</LinkButton>}
								</div>}
								{isMobile && <Divider />}
								{team.members && 
								<div className={ styles["teams__item__inner"] }>
									<div>
										<span className={ styles["teams__item__text"] }>{t('events.teams.assignedUsers')}</span> 
										<span className={ styles["teams__item__number"] }>
											{team.members.length === 0 ? !isMobile && 
											<i className='icon-circle-warning-empty' title={t('events.teams.warnings.addUsers')}/> : team.members.length} 
										</span>
									</div>
									{(team.members.length === 0 && isMobile) && 
									<div className={ styles["teams__item__alert"] }> 
										<i className='icon-circle-warning-empty' />
										<span>{t('events.teams.warnings.notAssigned')}</span>
									</div> }
									{isAdmin && !isFinished &&
									<Button size='small' variant='secondary' onClick={() => {setTeamForModal(team.name), setModalVisibleAddUsersTeam(true);}} >
										{t('events.teams.buttons.assigneUsers')}
									</Button>}
								</div>}
								{isAdmin && !isFinished && (team.name === firstTeamName ? 
									<div className={ styles["teams__item__button-reset-container"] }>
										<Button variant='tertiary' className={ styles["teams__item__button-reset"] } onClick={() => {setModalResetA(true), setTeamForModal(team.name);}}>
											{t('events.teams.buttons.defaultState')}
										</Button>
									</div>
									: 
									<div className={ styles["teams__item__button-reset-container"] }>
										<Button variant='tertiary' className={ styles["teams__item__button-delete"] } onClick={() => {setModalDeleteTeam(true), setTeamForModal(team.name);}}>
											{t('events.teams.buttons.deleteTeam')}
										</Button>
									</div>)}
							</div>
						</div>
					),
				)

			}
			<div className={ styles["teams__top"] }>
				<span className={styles['teams__title']}>{t('events.teams.attendees')} ({users})</span>
			</div>

			<div className={styles['teams__search__container']}>
				<input className={styles['teams__search']} onChange={findUsers} placeholder={t('events.teams.searchPlaceholder')} />
				{isAdmin && <Button variant='secondary' onClick={() => setModalSendMessage(true)}>{t('events.teams.buttons.sendMessage')}</Button>}
			</div>

			{isMobile && 
			<div className={styles['teams__select__container']}> 
				<Form.Control as='select' onChange={onChangeSelect}>
					<option key={unassignedTeamName} value={unassignedTeamName}>{t('events.teams.unassigned')}</option>
					{(assignedTeams && assignedTeams.length > 0) && assignedTeams.map((team) => (<option key={team.name} value={team.name}>{team.name} </option>)) }
				</Form.Control>
			</div>}
			{isMobile && mobileTeam && mobileTeam?.users && mobileTeam?.users.length > 0 && 
				<div className={ styles["teams__boxes"] }> 
					{mobileTeam.users.map(user => {
						return (
							<div key={user.name} className={ styles["teams__box"] }>
								<div className={ styles["teams__box__row"] }>
									<span className={ styles["teams__box__title"] }>{t('events.teams.statistics.place')}</span>
									<span className={ styles["teams__box__text"] } />
								</div>
								<div className={ styles["teams__box__row"] }>
									<span className={ styles["teams__box__title"] }>{t('events.teams.statistics.attendee')}</span>
									<span className={ styles["teams__box__text"] }>{user.name}</span>
								</div>
								<div className={ styles["teams__box__row"] }>
									<span className={ styles["teams__box__title"] }>{t('events.teams.statistics.status')}</span>
									<span className={ styles["teams__box__text"] }>{user.status ? t('events.teams.confirmed') : t('events.teams.unconfirmed')}</span>
								</div>
								<div className={ styles["teams__box__row"] }>
									<span className={ styles["teams__box__title"] }>{t('events.teams.statistics.signs')}</span>
									<span className={ styles["teams__box__text"] }>{user.characterCount}</span>
								</div>
								<div className={ styles["teams__box__row"] }>
									<span className={ styles["teams__box__title"] }>{t('events.teams.statistics.words')}</span>
									<span className={ styles["teams__box__text"] }>{user.wordCount}</span>
								</div>
								<div className={ styles["teams__box__row"] }>
									<span className={ styles["teams__box__title"] }>{t('events.teams.statistics.rows')}</span>
									<span className={ styles["teams__box__text"] }>{user.verseCount}</span>
								</div>
								{isAdmin && !isStarted && !isFinished &&
								<div className={ styles["teams__box__delete"] }>
									<Button variant='tertiary' onClick={() => { setModalDeleteUser(true); setUserToDelete(user); }}>
										{t('events.teams.deleteUser.button')}
									</Button>
								</div>}
							</div>);
						
					})}
				</div>			}
			{!isMobile && 
			<>
				<div className={ styles["teams__table"] }>
					<div className={ styles["teams__table__header"] }>
						<span>{t('events.teams.statistics.place')}</span>
						<span>{t('events.teams.statistics.attendee')}</span>
						<span>{t('events.teams.statistics.status')}</span>
						<span>{t('events.teams.statistics.signs')}</span>
						<span>{t('events.teams.statistics.words')}</span>
						<span>{t('events.teams.statistics.rows')}</span>
					</div>
					{activeAttendees.length > 0  && activeAttendees.map(attendee => {
						return (
							<div key={attendee.team || "null"}>
								<div className={ styles["teams__table__subheader"] }>
									<span />
									<span>{attendee.team || t('events.teams.unassigned')}</span>
									<span />
									<span>{attendee.team && getNumberCount(attendee.team, "characterCount")}</span>
									<span>{attendee.team && getNumberCount(attendee.team, "wordCount")}</span>
									<span>{attendee.team && getNumberCount(attendee.team, "verseCount")}</span>
									<span />
								</div>
								{attendee.users.map(user => {
									return (
										<div key={user.userId} className={ styles["teams__table__row"] }>
											<span />
											<span>{user.name}<br/>{user.email && <span className={ styles["teams__table__row__email"] }>{user.email}</span>}</span>
											<span>{user.status ? t('events.teams.confirmed') : t('events.teams.unconfirmed')}</span>
											<span>{user.characterCount}</span>
											<span>{user.wordCount}</span>
											<span>{user.verseCount}</span>
											{isAdmin && !isStarted && !isFinished &&
											<Button variant='tertiary' className={ styles["teams__table__delete"] } onClick={() => { setModalDeleteUser(true); setUserToDelete(user); }}>
												{t('events.teams.deleteUser.button')}
											</Button>}
										</div>); 
								})}
							</div>);
					})}
				</div>
			</>}
			{isMobile && <Divider />}
			{isAdmin && !isFinished &&
			<AddUser eventId={eventId} getTeams={getTeams} getEvent={getEvent} assignedTeams={assignedTeams}
				setTeams={setTeams} dataToSend={dataToSend}
			/>}
		</>
	);
};

Teams.propTypes = {
	teams: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			characterCount: PropTypes.number,
			members: PropTypes.array,
			transcriptionPages: PropTypes.object,
			name: PropTypes.string,
			verseCount: PropTypes.number,
			wordCount: PropTypes.number,
		}),
	),
	attendees: PropTypes.arrayOf(
		PropTypes.shape({
			team: PropTypes.string,
			users: PropTypes.arrayOf(PropTypes.shape({
				name: PropTypes.string,
				characterCount: PropTypes.number,
				verseCount: PropTypes.number,
				wordCount: PropTypes.number,
			})),
		}),
	),
	eventId: PropTypes.string, 
	setTeams: PropTypes.func,
	getTeams: PropTypes.func, 
	getEvent: PropTypes.func, 
	isAdmin: PropTypes.bool,
	isStarted: PropTypes.bool,
	isFinished: PropTypes.bool,
};

export default Teams;