import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import api from "@/services/api";
import Form from 'react-bootstrap/Form';

import { useTranslation } from 'react-i18next';
import { appActions } from "@/store/actions/app.actions";
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";

import FormSubmit from "@Elements/Form/FormSubmit";
import FormInputCustom from "@Elements/Form/FormInputCustom";
import emailsRegex from "@/utils/regexHelper";

import styles from "@Pages/Event/Event.module.sass";

const AddUser = ({eventId, getEvent, assignedTeams, dataToSend, setTeams}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const defaultValues = {
		userEmail: "",
		team: "",
	};
  
	const {
		register,
		handleSubmit,
		formState: {
			errors,
		},
		setValue,
		setError,
		reset,
	} = useForm({ defaultValues: defaultValues });
  
	const addUser = async (values) => {
		const knownEmails = dataToSend.reduce((result, team) => {
			team.members.map(member => result.push(member.email));
			return result;
		}, []);
		const toAdd = values.userEmail.match(/[^,\s]+/g).filter(email => !knownEmails.includes(email));
		if (toAdd.length === 0) {
			setError("userEmail", {
				type: "manual",
				message: t('events.teams.addUser.errors.userExist'),
			});
			return;
		}

		dispatch(appActions.setLoading(true));
		try {
			const res = await api.put(`/events/${eventId}/attendees?teamId=${values.team}`, toAdd);
			if (res.status === 200) {
				setTeams(res.data);
				getEvent();
				reset({ userEmail: "" });
				dispatch(appActions.setLoading(false));
				return;
			}
		} catch (error) {
			if (error.status === 400 && error.data.email === "noSuchUser") { 
				setError("userEmail", {
					type: "manual",
					message: t('events.teams.addUser.errors.userNotExist'),
				});
				dispatch(appActions.setLoading(false));
				return;
			}
		}
		setError("userEmail", {
			type: "manual",
			message: t('events.teams.addUser.errors.general'),
		});
		dispatch(appActions.setLoading(false));
	};

	useEffect(() => {
		if(assignedTeams[0]?.name) {
			setValue("team", assignedTeams[0].id);
		}
	}, [assignedTeams]);

	return (
		<div className={ styles["teams__add-user"] }>
			<form onSubmit={handleSubmit(addUser)}>
				<p className={ styles["teams__add-user__title"] }>{t('events.teams.addUser.title')}</p>
				<div className={ styles["teams__add-user__input"] }>
					<Form.Group controlId='userEmail'>
						<FormInputCustom
							register={register}
							errorMessage={errors?.userEmail?.message}
							isRequired
							label={t('events.teams.addUser.label')}
							name='userEmail'
							infoMessage={t('events.teams.addUser.info')}
							validateData={{
								required: {
									value: true,
									message: t('form.messages.emptyEmail'),
								},
								pattern: {
									value: emailsRegex,
									message: t('form.messages.invalidEmail'),
								},
							}}
						/>
					</Form.Group>
				</div>
				<div className={ styles["teams__add-user__select-container"] }>
					<select className={ styles["teams__add-user__select"] } {...register("team")}>
						{(assignedTeams && assignedTeams.length > 0) && assignedTeams.map((team) =>
							(<option key={team.name} value={team.id}>{team.name}</option>)) }
					</select>
				</div>
				<p className={ styles["teams__add-user__text-required"] }>{t('events.teams.addUser.required')}</p>
				<div className={ styles["teams__add-user__add-container"] }>
					<FormSubmit
						className={ styles["teams__add-user__add-button"] }
						label={t('events.teams.addUser.buttons.addUser')}
						variant='submit'
					/>
				</div>
			</form>
		</div>
	);
};

AddUser.propTypes = {
	eventId: PropTypes.string, 
	getTeams: PropTypes.func, 
	getEvent: PropTypes.func, 
	assignedTeams:  PropTypes.array,
	dataToSend: PropTypes.array, 
	setTeams: PropTypes.func,
};

export default AddUser;