import React from 'react';
import PropTypes from 'prop-types';
import ModalWrapperChildren from "@Elements/Modal/ModalWrapperChildren";
import styles from "@Pages/Event/Event.module.sass";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

const ModalPseudonymWarning = ({modalPseudonymWarning, setModalPseudonymWarning, currentPseudonym, signIn}) => {
	const { t } = useTranslation();

	const accountUrl = useSelector(state => state.app.config.accountUrl);

	return (
		<ModalWrapperChildren
			modalVisible={modalPseudonymWarning}
			setModalVisible={() => setModalPseudonymWarning(false)}
			title={t('events.pseudonymWarning.title')}
			closeButtonName={t('events.pseudonymWarning.cancel')}
			confirmButtonName={t('events.pseudonymWarning.confirm')}
			onConfirm={() => {signIn(), setModalPseudonymWarning(false);}}
			withFooter
		>
			<div className={ styles["teams__delete-event"] } >
				<span>{t('events.pseudonymWarning.description1')} <strong>{currentPseudonym}</strong> {t('events.pseudonymWarning.description2')} <a href={ accountUrl } target='_blank' rel='noreferrer noopener'>{t('events.pseudonymWarning.description3')}</a> {t('events.pseudonymWarning.description4')}</span>
			</div>
		</ModalWrapperChildren>
	);
};

ModalPseudonymWarning.propTypes = {
	modalPseudonymWarning: PropTypes.bool,
	setModalPseudonymWarning: PropTypes.func, 
	currentPseudonym: PropTypes.string,
	signIn: PropTypes.func,
};

export default ModalPseudonymWarning;