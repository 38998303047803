import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";

import { viewerActions } from "@/store/actions/viewer.actions";

import DropdownBox from "@Pages/Viewer/parts/ViewerEditor/parts/DropdownBox";
import FormattingDropdownButton from "@Pages/EventViewer/parts/ViewerEditor/parts/FormattingDropdownButton";
import FormattingButton from "@Pages/EventViewer/parts/ViewerEditor/parts/FormattingButton";

import Button from "@Elements/Button/Button";

const INLINE_STYLES = [
	{ label: "B", style: "BOLD", icon: "bold", tooltip: "viewer.tooltip.menu.style.BOLD" },
	{ label: "I", style: "ITALIC", icon: "italic", tooltip: "viewer.tooltip.menu.style.ITALIC" },
	{ label: "S", style: "STRIKETHROUGH", icon: "strike", tooltip: "viewer.tooltip.menu.style.STRIKETHROUGH" },
	{ label: "U", style: "UNDERLINE", icon: "underline", tooltip: "viewer.tooltip.menu.style.UNDERLINE" },
];

const StyleButton = ({ style, active, icon, tooltip = "" }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const verseEdit = useSelector(state => state.viewer.editor.verseEdit);
	const wordEdit = useSelector(state => state.viewer.editor.wordEdit);

	const setStyle = (event, value) => {
		event.preventDefault();
		dispatch(viewerActions.setStyleActive(value));
	};

	return (
		<div
			data-tooltip-id='custom-tooltip' data-tooltip-content={t('viewer.tooltip.menu.style.'+style) }
			data-place='right'
		>
			<Button
				variant='viewer-button'
				className={ `viewer__button--menu ${active ? "viewer__button--active" : ""}` }
				onMouseDown={ event => setStyle(event, style) }
				disabled={ !verseEdit && !wordEdit.wordId }
				data-tooltip-id='custom-tooltip' data-tooltip-content={ tooltip }
			>
				<span className='d-none'>{icon}</span>
				<i className={ `icon-${icon}` } />
			</Button>
		</div>
	);
};

StyleButton.propTypes = {
	style: PropTypes.string,
	active: PropTypes.bool,
	icon: PropTypes.string,
	tooltip: PropTypes.string,
};

const ViewerEditorCategory = ({ icon, tooltip, disabled, children }) => {
	const [expand, setExpand] = useState(false);

	const onButtonClick = () => {
		setExpand(!expand);
	};
		
	return (
		<>
			<div
				data-tooltip-id='custom-tooltip' data-tooltip-content={ tooltip }
				data-place='right'
			>
				<Button
					variant='viewer-button-category'
					onClick={ () => onButtonClick() }
					disabled={ disabled }
				>
					{ expand && !disabled ? <i className='expand-category icon-arrow-down-full' /> 
						: <i className='expand-category icon-arrow-right-full' /> }
					<span className='d-none'>{icon}</span>
					<i className={ `icon-${icon}` } />
				</Button>
			</div>
			{expand && !disabled && (
				children
			)}
		</>
	);
};

ViewerEditorCategory.propTypes = {
	icon: PropTypes.string,
	tooltip: PropTypes.string,
	disabled: PropTypes.bool,
	children: PropTypes.array,
};

const ViewerEditorMenu = ({ anyVerseActive, anyWordActive, canBlockBeCreated }) => {
	const currentStyle = useSelector(state => state.viewer.editor.styleCurrent);
	const { t } = useTranslation();

	const showTextStructure = useSelector(state => state.viewer.showTextStructure && !state.viewer.annotationLayer);
	const annotationLayer = useSelector(state => state.viewer.annotationLayer);
	const verseEdit = useSelector(state => state.viewer.editor.verseEdit);
	const wordEdit = useSelector(state => state.viewer.editor.wordEdit);
	const verseActive = useSelector(state => state.viewer.editor.verseActive);
	const verseGroupActive = useSelector((state) => state.viewer.editor.verseGroupActive);
	const wordActive = useSelector(state => state.viewer.editor.wordActive);
	const wordGroupActive = useSelector((state) => state.viewer.editor.wordGroupActive);
	const verses = useSelector(state => state.viewer.data.verses);

	const viewMode = useSelector((state) => state.viewer.data.viewMode);
	const student = viewMode === 'student';
	
	const indexesDisabled = showTextStructure || (!verseActive && !wordActive?.wordId) || !!verseEdit || !!wordEdit.wordId || student;

	return (
		<ul className='viewer__menu--list'>
			<ViewerEditorCategory 
				disabled={ !verseEdit && !wordEdit.wordId }
				icon='text-formatting' 
				tooltip={t('viewer.tooltip.menu.categoryTextFormatting')}
			>
				{INLINE_STYLES.map(type => (
					<li key={ type.style }>
						<StyleButton
							key={ type.label }
							active={ currentStyle.size ? currentStyle.has(type.style) : false }
							label={ type.label }
							style={ type.style }
							icon={ type.icon }
							tooltip='' 
						/>
					</li>
				))}
			</ViewerEditorCategory>
			<ViewerEditorCategory
				disabled={indexesDisabled}
				icon='instapaper' 
				tooltip={showTextStructure ? t('viewer.tooltip.menu.categoryIndexesTextStructureError') : t('viewer.tooltip.menu.categoryIndexes')}
			>
				<li>
					<FormattingButton
						icon='index-place'
						func='verse-set-as-index'
						indexType='place'
						tooltip={t('viewer.tooltip.menu.setAsPlace')}
						data-tooltip-id='custom-tooltip' data-tooltip-content={t('viewer.tooltip.menu.setAsPlace')}
						disabled={indexesDisabled}
					/>
				</li>
				<li>
					<FormattingButton
						icon='index-term'
						func='verse-set-as-index'
						indexType='term'
						tooltip={t('viewer.tooltip.menu.setAsTerm')}
						data-tooltip-id='custom-tooltip' data-tooltip-content={t('viewer.tooltip.menu.setAsTerm')}
						disabled={indexesDisabled}
					/>
				</li>
				<li>
					<FormattingButton
						icon='index-person'
						func='verse-set-as-index'
						indexType='person'
						tooltip={t('viewer.tooltip.menu.setAsPerson')}
						data-tooltip-id='custom-tooltip' data-tooltip-content={t('viewer.tooltip.menu.setAsPerson')}	
						disabled={indexesDisabled}
					/>
				</li>
			</ViewerEditorCategory>
			<ViewerEditorCategory 
				icon='verse-management' 
				tooltip={t('viewer.tooltip.menu.categoryVerseEdition')}
				disabled={student}
			>
				<li>
					<FormattingButton
						disabled={
							!anyVerseActive ||
								!!verseEdit ||
								annotationLayer ||
								student
						}
						icon='verse-auto-transcription'
						func='recognize-content'
						tooltip={t('viewer.tooltip.menu.verseAutoTranscription') }
					/>
				</li>

				<li>
					<FormattingButton
						visible
						disabled={!verseActive || !!verseEdit || student}
						icon='verse-comment'
						func='verse-comment'
						tooltip={t('viewer.tooltip.menu.addComment') }
					/>
				</li>

				<li>
					<DropdownBox
						tooltip={t('viewer.tooltip.menu.deleting') }
						disabled={ !verses.length || student }
						icon='verse-delete'
					>
						<FormattingDropdownButton
							icon='verse-delete'
							func='verse-delete'
							disabled={ (!verseActive && verseGroupActive.firstVerseNo === null) || !!verseEdit || student }
							label={t('viewer.tooltip.menu.verseDelete') }
						/>
						<FormattingDropdownButton
							icon='verse-delete'
							func='word-delete'
							disabled={ (!wordActive.wordId && wordGroupActive.firstWordNo === null) || wordEdit.wordId || student }
							label={t('viewer.tooltip.menu.wordDelete') }
						/>
						<FormattingDropdownButton
							icon='verse-delete'
							func='verses-delete'
							disabled={ student }
							label={t('viewer.tooltip.menu.versesDelete') }
						/>
					</DropdownBox>
				</li>

				<li>
					<FormattingButton
						disabled={
							!anyVerseActive ||
								!!verseEdit ||
								student
						}
						icon='verse-clear'
						func='clear-verses'
						tooltip={t('viewer.tooltip.menu.verseClear') }
					/>
				</li>

				<li>
					<FormattingButton
						disabled={!anyVerseActive || !!verseEdit || student}
						icon='verse-up'
						func='move-up'
						tooltip={t('viewer.tooltip.menu.verseUp') }
					/>
				</li>

				<li>
					<FormattingButton
						disabled={!anyVerseActive || !!verseEdit || student}
						icon='verse-down'
						func='move-down'
						tooltip={t('viewer.tooltip.menu.verseDown') }
					/>
				</li>

				<li>
					<FormattingButton
						disabled={!anyVerseActive || !!verseEdit || student}
						icon='move-verse-10-up'
						func='move-up-10'
						tooltip={t('viewer.tooltip.menu.verseUp10') }
					/>
				</li>

				<li>
					<FormattingButton
						disabled={!anyVerseActive || !!verseEdit || student}
						icon='move-verse-10-down'
						func='move-down-10'
						tooltip={t('viewer.tooltip.menu.verseDown10') }
					/>
				</li>
			</ViewerEditorCategory>
		</ul>
	);
};

ViewerEditorMenu.propTypes = {
	anyVerseActive: PropTypes.bool,
	anyWordActive: PropTypes.bool,
	canBlockBeCreated: PropTypes.bool,
};

export default memo(ViewerEditorMenu);
