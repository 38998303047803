import React, {useEffect, useState} from 'react';
import api from "@/services/api";
import url from "@/router/urls";

import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from "@/store/actions/app.actions";
import { alertActions } from "@/store/actions/alert.actions";
import { reverse } from 'named-urls';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";

import Error403 from "@Pages/Error/Error403";
import Button from "@Elements/Button/Button";
import LinkButton from "@Elements/Button/LinkButton";
import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";
import Alert from "@Elements/Alert/Alert";
import Pagination from "@Components/Pagination/Pagination";
import CheckMark from '@Assets/icons/Check_circle.svg';
import Form from 'react-bootstrap/Form';
import TranscriptionsItemPage from '@Elements/Transcriptions/TranscriptionsItemPage';
import styles from "@Pages/EventPages/EventPages.module.sass";

const EventPages = () => {   
	const [haveAcess, setHaveAccess] = useState(false);
	const [event, setEvent] = useState('');
	const [teams, setTeams] = useState([]);
	const [activeTeam, setActiveTeam] = useState(null);
	const [transcriptions, setTranscriptions] = useState([]);
	const [selectedTranscription, setSelectedTranscription] = useState(null);
	const [selectedTranscriptionPages, setSelectedTranscriptionPages] = useState([]);
	const [currentPage, setCurrentPage]= useState(1);
	const [onlyUnAssignedPages, setOnlyUnAssignedPages] = useState(false);
	const [numberPagesActiveTeam, setNumberPagesActiveTeam] = useState(0);
	const [clickedPage, setClickedPage] = useState([]);
	const [timeoutId, setTimeoutId] = useState(null);
	const [pendingLoads, setPendingLoads] = useState(0);

	const { t } = useTranslation();
	const { id, teamId } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const userEmail = useSelector(state => state.auth.credentials.email);
	const securityGroup = useSelector((state) => state.auth.credentials.securityGroup);
	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);

	const breadcrumbsList = [
		{ label: "home", id: 1 },
		{ label: t('events.allEvents') , id: 2, link: url.events },
		{ label: event ? event.name : t('events.event'), id: 3, link: reverse(`${url.event}`, { id: id }) },
		{ label: t('events.transcriptions.projects'), id: 4 },
	];

	const getEvent = async () => {
		startLoading();
		try {
			const res = await api.get(`/events/${id}`);

			if(res.status === 200) {
				setEvent(res.data);
			}
			endLoading();
		} catch (error) {
			endLoading();
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text:  t('events.pages.warnings.event'),
				close: true,
			}));
		}
	};

	const getTeams = async () => {
		startLoading();
		try {
			const res = await api.get(`/events/${id}/teams`);

			if(res.status === 200) {
				const teamsData = res.data.map(team => 
					({ id: team.id, name: team.name, numberOfPages: Object.values(team.transcriptionPages).reduce((acc, val) => { return acc + val.length; }, 0) }),
				);
				teamsData.push({ name: '', id: 0, numberOfPages: 0 });
				setTeams(teamsData);
			}
			endLoading();
		} catch (error) {
			endLoading();
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.pages.warnings.teams'),
				close: true,
			}));
		}
	};

	const teamSelected = (newTeamId) => {
		setActiveTeam(newTeamId);
		history.replace(reverse(url.eventPages, { id: id, teamId: newTeamId }));
	};

	const addPagesToTeam = async (add, idsPage, teamIdToRemoveFrom) => {
		startLoading();
		try {
			if(add) {
				const res = await api.put(`/events/${id}/teams/${activeTeam}/pages`, idsPage);

				if (res.status === 200) {
					getTranscriptionPages(selectedTranscription, currentPage, activeTeam, onlyUnAssignedPages);
					getTeams();
					setNumberPagesActiveTeam(numberPagesActiveTeam + idsPage.length);
				}
			} else {
				const res = await api.delete(`/events/${id}/teams/${teamIdToRemoveFrom}/pages`, {data: idsPage});

				if (res.status === 200) {
					getTranscriptionPages(selectedTranscription, currentPage, activeTeam, onlyUnAssignedPages);
					getTeams();
					if(teamIdToRemoveFrom === Number(activeTeam)) {
						setNumberPagesActiveTeam(numberPagesActiveTeam - 1);
					}
				}
			}
			
			endLoading();
		} catch (error) {
			endLoading();
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text:  t('events.pages.warnings.addPages'),
				close: true,
			}));
		}
	};

	const getTranscriptions = async () => {
		startLoading();
		try {
			const res = await api.get(`/events/${id}/transcriptions?perpage=100`);
			if (res.status === 200) {
				setTranscriptions(res.data.transcriptions);
				if(res.data.transcriptions.length > 0) {
					setSelectedTranscription(res.data.transcriptions[0].id);
				}
				
				endLoading();
			}
		} catch (error) {
			endLoading();
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.pages.warnings.getTranscriptions'),
				close: true,
			}));
		}
	};

	useEffect(() => {
		if(id) {
			getEvent();
			getTeams();
			getTranscriptions();
		}
	}, [id]);

	useEffect(() => {
		const admin = CheckSecurityGroupPermission("canCreateEvents", securityGroup);

		if(admin) {
			setHaveAccess(admin);
		} else if (id && !admin && event && event.organizers) {
			setHaveAccess(event.organizers.some(organizer => userEmail === organizer.email));
		} else {
			setHaveAccess(false);
		}
	}, [securityGroup, event, id, isLoggedIn]);

	const getTranscriptionPages = async (transcriptionId, page, teamId, hideOtherTeams) => {
		startLoading();
		try {
			let res;
			if	(hideOtherTeams) {
				res = await api.get(`/events/${event.id}/transcriptions/${transcriptionId}/pages?teamId=${teamId}&includeUnassigned=true&perpage=24&page=${page}`);
			} else {
				res = await api.get(`/events/${event.id}/transcriptions/${transcriptionId}/pages?includeUnassigned=true&perpage=24&page=${page}`);
			}
			
			if (res.status === 200) {
				setSelectedTranscriptionPages(res.data);
				endLoading();
			}
		} catch (error) {
			endLoading();
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.pages.warnings.getTranscription'),
				close: true,
			}));
		}
	};

	const signAllPagesWithoutTeam = () => {
		if (selectedTranscriptionPages) {
			const pagesToSend = selectedTranscriptionPages.list.filter(page => !page.eventTeam).map(page => page.id);
			addPagesToTeam(true, pagesToSend);
		}
	};

	const startLoading = () => {
		setPendingLoads(pendingLoads + 1);
	};

	const endLoading = () => {
		setPendingLoads(pendingLoads - 1);
	};

	useEffect(() => {
		dispatch(appActions.setLoading(pendingLoads > 0));
	}, [pendingLoads]);

	useEffect(() => {
		if(selectedTranscription) {
			getTranscriptionPages(selectedTranscription, currentPage, activeTeam, onlyUnAssignedPages);
		}
	}, [currentPage, selectedTranscription, activeTeam, onlyUnAssignedPages]);

	useEffect(() => {
		if(teams.length > 0 && teamId) {
			const teamToLoad = teams.find(team => team.id === Number(teamId));
			setActiveTeam(teamToLoad.id);
		} else if(teams.length > 0) {
			const teamToLoad = teams.find(team => team.id != 0);
			setActiveTeam(teamToLoad.id);
		}
	}, [teams, teamId]);

	useEffect(() => {
		if(activeTeam) {
			const team = teams.find(el => { return el.id == activeTeam; });
			setNumberPagesActiveTeam(team.numberOfPages);
		}		
	}, [activeTeam]);

	useEffect(() => {
		if (clickedPage.length > 0) {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			const newTimeoutId = setTimeout(() => {
				setClickedPage([]);
			}, 3000);

			setTimeoutId(newTimeoutId);
		}
	}, [clickedPage]);

	if (!haveAcess) return <Error403 />;

	return (
		<>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
			</Container>

			<Container>
				<Alert />
				<div className={styles["event-pages__back"]}>
					<LinkButton variant='text' href={reverse(`${url.event}`, { id: id, tab: "teams" })}>{t('events.pages.buttons.back')}</LinkButton>
				</div>
				<div className={styles["event-pages__title"]}>
					<p className={styles["event-pages__title"]}>{t('events.pages.title')} ({numberPagesActiveTeam})</p>
				</div>
				<div className={styles["event-pages__description"]}>
					<p className={styles["event-pages__subtitle"]}>{t('events.pages.subTitle')}</p>
					<div className={styles["event-pages__select"]}>
						<Form.Control as='select' onChange={(e) => {teamSelected(e.target.value);}} value={activeTeam ? activeTeam : ''}>
							{teams.map(team => team.name != '' && <option value={team.id} key={team.id}>{team.name}</option>)}
						</Form.Control>
					
					</div>
				</div>
				<div className={styles["event-pages__buttons"]}>
					{transcriptions.map(trn => {
						return(
							<Button className={[styles[`event-pages__button-transcription`], trn.id === selectedTranscription && styles[`event-pages__button-transcription--active`]]} 
								key={trn.id} onClick={() => {setSelectedTranscription(trn.id), setCurrentPage(1);}}
							>
								<span title={trn.titleLabel}>{trn.titleLabel}</span>
							</Button>);
					})}
				</div>
			</Container>
			<div className={styles["event-pages__pages"]}>
				<Container>
					<Pagination
						currentPage={selectedTranscriptionPages.page}
						pagesNum={selectedTranscriptionPages.pages}
						onGoToPage={setCurrentPage}
						leftTopChildren={
							<div className={styles["event-pages__buttons-assign-hide"]}>
								<Button className={styles["event-pages__buttons-assigne-all"]} variant='secondary' onClick={() => signAllPagesWithoutTeam()}>{t('events.pages.assignUnassigned')}</Button>
								<div className={styles["event-pages__hide"]}>
									<input id='hide-assigned' className={styles["event-pages__hide-checkbox"]} type='checkbox' checked={onlyUnAssignedPages}
										onChange={(e)=> setOnlyUnAssignedPages(e.target.checked)}
									/><label className={styles["event-pages__hide-label"]} htmlFor='hide-assigned'>{t('events.pages.hideAssigned')}</label>
								</div>
							</div>
						}
					>
						<div className={styles["event-pages__row"]}>
							{selectedTranscriptionPages.list?.map(page => {
								return (
									<div className={styles["event-pages__item"]} key={page.id}>
										<div className={styles["event-pages__mask"]}>
											<div className={styles["event-pages__add"]}>
												<input type='checkbox' value={page.id} checked={page.eventTeam ? true : false}
													id={page.id}
													onChange={(e) => {
														addPagesToTeam(e.target.checked, [page.id], page.eventTeam?.id);
														e.target.checked && setClickedPage([...clickedPage, page.id]); 
													}}
												/><label htmlFor={page.id}>{page.eventTeam?.name}</label>
											</div>
											{clickedPage && clickedPage.includes(page.id) && 
											<div className={styles[`event-pages__added-mask`]}>
												<div className={styles[`event-pages__added-mask-inner`]}>
													<div>
														<img src={CheckMark} alt='checkmark' />
													</div>
													{ page.eventTeam && <div className={styles[`event-pages__added-mask__text`]}>{t('events.pages.addedInfo')} <br /> <strong>{page.eventTeam?.name}</strong></div> }
												</div>
											</div>}
										</div>
										<TranscriptionsItemPage
											key={page.id}
											page={page}
											transcriptionId={page.id}
											percentOfVerified={page.percentOfTranscribed}
										/>
									</div>
								);
							})}
						</div>
					</Pagination>
				
				</Container>
			</div>
		</>
	);
};

EventPages.propTypes = {};

export default EventPages;